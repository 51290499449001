import React, { Component } from 'react'
import ReactTwitchEmbedVideo from 'react-twitch-embed-video'

class TwitchPlayer extends Component {
  render() {
    return (
      <div>
        <div className="twitch-player-wrapper">
          {typeof window !== 'undefined' && (
            <ReactTwitchEmbedVideo
          channel="fpbrault"
          theme="dark"
          width="100%"
          height="100%"
        />
          )}
        </div>
      </div>
    )
  }
}
export default TwitchPlayer
