import React from 'react'
import Layout from '../components/layout'
import TwitchPlayer from '../components/twitchplayer'

const LivePage = () => (
  <Layout fullwidth={true}>
    <div>
     <TwitchPlayer/>
    </div>
  </Layout>
)

export default LivePage
